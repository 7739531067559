import { useState } from "react";
import { useWindowContext } from "../WindowViewContext";
import { defineWindowDetails } from "../Window";
import { useInitWindowSize } from "../WindowViewContext";
import { GridItem, Grid } from "../../layout/Grid";
import { ButtonGroup } from "../../layout/ButtonGroup";
import { PendingOperationStatus } from "../../utils/PendingOperationStatus";
import { authService } from "../../../apinet";
import { RejectedPromiseUserFeedback, usePendingOperation } from "../../utils";
import { Button, Form, FormItem, TextInput } from "../../forms";
import { Text } from "core/content";
import { tx, useTr } from "core/intl/intlHelpers";
import { systemState } from "core/global";

interface VerifyUserDialogProps {
  confirmLabel?: string;
  operationDescription?: string;
}

defineWindowDetails(VerifyUserDialog, {
  icon: "fas fa-question",
  title: tx("Potwierdzenie operacji"),
  allowInPresentationMode: true,
});

export function VerifyUserDialog(props: VerifyUserDialogProps) {
  const impersonatingUser = systemState.useGlobalState(gs => gs.impersonatingUser);
  useInitWindowSize(400, impersonatingUser ? 300 : 270);
  const [password, setPassword] = useState("");
  const wnd = useWindowContext();
  const op = usePendingOperation();
  const tr = useTr();

  const cancel = () => {
    wnd.close(false);
  };

  const accept = () => {
    if (op.syncPending) return;
    if (!password) return;

    op.handle(
      authService
        .verifyCurrentUserPassword({
          password,
        })
        .then(res => {
          if (!res.success) {
            return Promise.reject(new RejectedPromiseUserFeedback(tr("Nieprawidłowe hasło")));
          } else {
            wnd.close(true);
          }
        })
    );
  };

  return (
    <Grid gap>
      <GridItem rowTemplate="1fr">
        {props.operationDescription && <div className="mb-8">{props.operationDescription}</div>}
        <div className="mb-8">
          <Text>Operacja wymaga potwierdzenia poprzez wpisanie hasła użytkownika</Text>:
        </div>
        <Form className="input-full-width">
          <FormItem>
            <div style={{ overflow: "hidden" }}>
              <input
                type="text"
                name="login"
                style={{
                  position: "absolute",
                  height: "1px",
                  width: "1px",
                  padding: "0px",
                  border: "0",
                  left: "-10px",
                }}
              />
              <TextInput
                type="password"
                name="verify_password"
                placeholder="Hasło"
                value={password}
                onChange={setPassword}
                autoFocus
                onEnter={accept}
                autoComplete="off"
              />
            </div>
            {impersonatingUser && (
              <Text block small warning>
                {tr(
                  'Uwaga - jesteś w trybie dostępu serwisowego do innego konta w związku z czym oczekiwane jest hasło faktycznego użytkownika "{{user}}"',
                  { user: impersonatingUser.label }
                )}
              </Text>
            )}
          </FormItem>
          <FormItem>
            <PendingOperationStatus
              inline
              op={op.state}
              errorTxt="Błąd weryfikacji"
              pendingTxt="Weryfikacja hasła..."
              successTxt="Pomyślna weryfikacja"
            />
          </FormItem>
        </Form>
      </GridItem>

      <GridItem rowTemplate="auto" className="text-right">
        <ButtonGroup>
          <Button onClick={cancel} disabled={op.state.pending}>
            Anuluj
          </Button>
          <Button cta onClick={accept} disabled={!password || op.state.pending}>
            {props.confirmLabel || <Text>Potwierdź</Text>}
          </Button>
        </ButtonGroup>
      </GridItem>
    </Grid>
  );
}
