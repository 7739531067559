import React, { Suspense } from "react";
import "./WindowView.scss";
import { WindowViewContext, IWindowViewContext } from "./WindowViewContext";
import { WindowInstance } from "./Window";
import { Subject, Subscription } from "rxjs";
import { getNextPosition } from "./WindowInitPositionProvider";
import { toClassNames } from "../utils/reactHelpers";
import desktopManager from "./desktopManager";
import dragHelper from "../utils/dragHelper";
import { SimpleContainer } from "../layout/SimpleContainer";
import { Icon } from "../utils/Icon";
import { desktopState, WindowEdgeDetection } from "../global/desktopState";
import { Text } from "../content";
import { WindowLockMode, WindowLockModeButton } from "./WindowLockModeButton";
import { WindowContextHelpButton } from "./WindowContextHelpButton";
import { StandardLazyComponentFallback } from "core/utils/StandardLazyComponentFallback";
import { StandardErrorBoundary } from "core/utils/StandardErrorBoundary";
import { systemState } from "core/global";

interface WindowViewProps<TProps> {
  windowInstance: WindowInstance<TProps>;
  browserResize$?: Subject<{}>;
  onDragEnd?: () => any;
  onResizeEnd?: () => any;
}

interface WindowViewState extends IWindowViewContext {
  initialized: boolean;
  maximized: boolean;
  lockMode: WindowLockMode;
  preMaximizeSize?: WindowLocation;
  unexpectedContentError: boolean;
  currentDetectedEdge: WindowEdgeDetection;
}

export interface WindowLocation {
  width: string;
  height: string;
  top: string;
  left: string;
}

export interface WindowViewSerializedState {
  maximized: boolean;
  lockMode: WindowLockMode;
  windowLocation: WindowLocation;
  preMaximizeSize?: WindowLocation;
}

const taskbarHeight = 40;
export class WindowView<TProps> extends React.Component<WindowViewProps<TProps>, WindowViewState> {
  rootRef = React.createRef<HTMLDivElement>();
  lastIsActive = false;
  browserResizeSub: Subscription;
  contentComponentSerializedState: any;

  constructor(props: WindowViewProps<TProps>) {
    super(props);

    this.onTitleMouseDown = this.onTitleMouseDown.bind(this);
    this.activateWindow = this.activateWindow.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.setInitPosition = this.setInitPosition.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setTitleExtension = this.setTitleExtension.bind(this);
    this.setSize = this.setSize.bind(this);
    this.setMinSize = this.setMinSize.bind(this);
    this.setInitSize = this.setInitSize.bind(this);
    this.close = this.close.bind(this);
    this.onXClick = this.onXClick.bind(this);
    this.maximize = this.maximize.bind(this);
    this.maximizeOnInit = this.maximizeOnInit.bind(this);
    this.minimize = this.minimize.bind(this);
    this.onRootClick = this.onRootClick.bind(this);
    this.onRootMouseOver = this.onRootMouseOver.bind(this);
    this.onRootMouseDown = this.onRootMouseDown.bind(this);
    this.onResizeStart = this.onResizeStart.bind(this);
    this.onResizeEnd = this.onResizeEnd.bind(this);
    this.onDragMove = this.onDragMove.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onBrowserWindowResize = this.onBrowserWindowResize.bind(this);
    this.setLockMode = this.setLockMode.bind(this);
    this.setContentComponentSerializedState = this.setContentComponentSerializedState.bind(this);

    this.lastIsActive = this.props.windowInstance.isActive;

    const restoredState = this.props.windowInstance.restoreState?.windowView;

    this.state = {
      initialized: false,
      unexpectedContentError: false,
      maximized: restoredState?.maximized || false,
      lockMode:
        restoredState?.lockMode !== undefined
          ? restoredState?.lockMode || WindowLockMode.noLock
          : WindowLockMode.noLock,
      preMaximizeSize: restoredState?.preMaximizeSize,
      dragging: false,
      onResize: new Subject<{}>(),
      onClick: new Subject<{}>(),
      onActivate: new Subject<{}>(),
      setPosition: this.setPosition,
      setInitPosition: this.setInitPosition,
      setSize: this.setSize,
      setMinSize: this.setMinSize,
      setInitSize: this.setInitSize,
      setTitle: this.setTitle,
      setTitleExtension: this.setTitleExtension,
      maximize: this.maximize,
      maximizeOnInit: this.maximizeOnInit,
      close: this.close,
      activate: this.activateWindow,
      setContentComponentSerializedState: this.setContentComponentSerializedState,
      restoreState: this.props.windowInstance.restoreState,
      ...desktopState.connect(this, gs => ({
        currentDetectedEdge: gs.currentEdgeDetection,
      })),
    };
  }

  static getDerivedStateFromError(error: any): Partial<WindowViewState> | null {
    return { unexpectedContentError: true };
  }

  render() {
    const { isActive: active, component, title, titleExtension } = this.props.windowInstance;
    const { maximized, lockMode } = this.state;
    const { icon, onlySu, contextHelpCode } = component;
    const isLockedDueToPresentationMode = this.isLockedDueToPresentationMode();

    return (
      <WindowViewContext.Provider value={this.state}>
        <div
          ref={this.rootRef}
          className={toClassNames({ "window-view": true, active })}
          style={this.getWindowStyle()}
          onClick={this.onRootClick}
          onMouseDown={this.onRootMouseDown}
          onMouseOver={this.onRootMouseOver}
        >
          <div className="window-top-bar">
            <div className="window-top-bar-title" onMouseDown={this.onTitleMouseDown} onDoubleClick={this.maximize}>
              {icon && (
                <div className="icon-container">
                  <i className={icon} />
                </div>
              )}
              {onlySu && <span>[SU]&nbsp;</span>}
              <Text>{title}</Text>
              &nbsp;
              {titleExtension}
            </div>

            <div className="window-top-bar-lock">
              {!isLockedDueToPresentationMode && <WindowLockModeButton value={lockMode} onChange={this.setLockMode} />}
            </div>
            <div className="window-top-bar-controls">
              {!isLockedDueToPresentationMode && <WindowContextHelpButton code={component.contextHelpCode} />}
              <button onClick={this.minimize} disabled={isLockedDueToPresentationMode}>
                <Icon symbol="windowMinimize" />
              </button>
              <button onClick={this.maximize} disabled={isLockedDueToPresentationMode}>
                <Icon symbol={maximized ? "windoweRestore" : "windowMaximize"} />
              </button>
              <button onClick={this.onXClick} disabled={isLockedDueToPresentationMode}>
                <Icon symbol="times" />
              </button>
            </div>
          </div>
          <div className="window-content">
            {this.renderContentEdgeInfo()}
            {this.renderWindowContent()}
          </div>
        </div>
      </WindowViewContext.Provider>
    );
  }

  renderContentEdgeInfo() {
    if (!this.state.dragging) return null;

    const edge = desktopState.state.currentEdgeDetection;
    if (edge === "inactive" || edge === "activeNone") return null;

    return (
      <div className="window-content-edge-info">
        <span className={`edge-${edge}`}>
          <i className="fas fa-arrow-up" />
        </span>
      </div>
    );
  }

  renderWindowContent() {
    if (!this.state.initialized) return null;

    const ContentComponent = this.props.windowInstance.component;
    const contentProps = this.props.windowInstance.props as TProps & JSX.IntrinsicAttributes;
    const result = (
      <StandardErrorBoundary category="window" description={this.props.windowInstance.title}>
        <Suspense fallback={<StandardLazyComponentFallback />}>
          <ContentComponent {...contentProps} />
        </Suspense>
      </StandardErrorBoundary>
    );
    return ContentComponent.noPadding ? result : <SimpleContainer padding>{result}</SimpleContainer>;
  }

  componentDidMount() {
    this.initWindowDefaults();
    if (this.props.browserResize$) {
      this.browserResizeSub = this.props.browserResize$.subscribe(this.onBrowserWindowResize);
    }
  }

  componentWillUnmount() {
    if (this.browserResizeSub) {
      this.browserResizeSub.unsubscribe();
    }
    this.props.windowInstance.windowViewHandlingInstance = undefined;
  }

  componentDidUpdate(prevProps: WindowViewProps<TProps>) {
    this.props.windowInstance.windowViewHandlingInstance = this;
    if (this.props.windowInstance.isActive && !this.lastIsActive) {
      this.state.onActivate.next({});
    }

    this.lastIsActive = this.props.windowInstance.isActive;
  }

  onBrowserWindowResize() {
    this.state.onResize.next({});
  }

  onResizeStart() {
    this.setState({
      dragging: true,
    });
  }

  onResizeEnd() {
    this.setState({
      dragging: false,
    });
    this.state.onResize.next({});
    this.props.onResizeEnd && this.props.onResizeEnd();
  }

  onDragMove(ev: MouseEvent) {
    const threshold = 50;
    const left = ev.clientX < threshold;
    const top = ev.clientY < threshold;
    const right = window.innerWidth - ev.clientX < threshold;
    const bottom = window.innerHeight - ev.clientY - taskbarHeight < threshold;
    const hMiddlePoint = window.innerWidth / 2;
    const vMiddlePoint = (window.innerHeight - taskbarHeight) / 2;
    const hCenter = ev.clientX > hMiddlePoint - 100 && ev.clientX < hMiddlePoint + 100;
    const vCenter = ev.clientY > vMiddlePoint - 100 && ev.clientY < vMiddlePoint + 100;

    let edge: WindowEdgeDetection = "activeNone";

    if (top) {
      if (left) {
        edge = "top-left";
      } else if (right) {
        edge = "top-right";
      } else if (hCenter) {
        edge = "top";
      }
    } else if (bottom) {
      if (left) {
        edge = "bottom-left";
      } else if (right) {
        edge = "bottom-right";
      } else if (hCenter) {
        edge = "bottom";
      }
    } else if (left && vCenter) {
      edge = "left";
    } else if (right && vCenter) {
      edge = "right";
    }

    desktopState.setDetectedWindowEdge(edge);
  }

  onDragEnd() {
    this.setEdgeWindowSize();

    desktopState.setDetectedWindowEdge("inactive");
    this.setState({ dragging: false });
    this.props.onDragEnd && this.props.onDragEnd();
  }

  setEdgeWindowSize() {
    const edge = desktopState.state.currentEdgeDetection;
    const el = this.rootRef.current!;
    const fullHeight = window.innerHeight - taskbarHeight;
    const fullWidth = window.innerWidth;

    switch (edge) {
      case "top":
        el.style.top = "0px";
        el.style.left = "0px";
        el.style.width = `${fullWidth}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      case "bottom":
        el.style.top = `${fullHeight / 2}px`;
        el.style.left = "0px";
        el.style.width = `${fullWidth}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      case "left":
        el.style.top = "0px";
        el.style.left = "0px";
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight}px`;
        break;

      case "right":
        el.style.top = "0px";
        el.style.left = `${fullWidth / 2}px`;
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight}px`;
        break;

      case "top-left":
        el.style.top = "0px";
        el.style.left = "0px";
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      case "top-right":
        el.style.top = "0px";
        el.style.left = `${fullWidth / 2}px`;
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      case "bottom-right":
        el.style.top = `${fullHeight / 2}px`;
        el.style.left = `${fullWidth / 2}px`;
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      case "bottom-left":
        el.style.top = `${fullHeight / 2}px`;
        el.style.left = "0px";
        el.style.width = `${fullWidth / 2}px`;
        el.style.height = `${fullHeight / 2}px`;
        break;

      default:
        return;
    }

    this.onResizeEnd();
  }

  onXClick() {
    this.close();
  }

  initWindowDefaults() {
    const cmp = this.props.windowInstance.component;
    this.setTitle(cmp.title || cmp.name || "?");

    if (!this.state.maximized) {
      this.setSize([500, 400]);
      this.setPosition(cmp.overrideStartPosition ? cmp.overrideStartPosition() : getNextPosition());
    }
    this.setState({
      initialized: true,
    });

    const windowViewRestoreState = this.props.windowInstance.restoreState?.windowView;

    if (windowViewRestoreState?.windowLocation) {
      this.setWindowLocation(windowViewRestoreState.windowLocation);
    }
  }

  setTitle(title: string) {
    desktopManager.setTitle(this.props.windowInstance, title);
  }

  setTitleExtension(titleExtension: string) {
    desktopManager.setTitleExtension(this.props.windowInstance, titleExtension);
  }

  setInitSize(widthHeight: [number, number]) {
    if (this.isRestoredState()) return;

    this.setSize(widthHeight);
  }

  setSize(widthHeight: [number, number]) {
    if (this.state.maximized) {
      console.warn("Trying to set size of maximized window");
      return;
    }

    const el = this.rootRef.current!;

    el.style.width = widthHeight[0] + "px";
    el.style.height = widthHeight[1] + "px";
    this.onResizeEnd();
  }

  setMinSize(widthHeight: [number | undefined, number | undefined]) {
    const el = this.rootRef.current!;

    el.style.minWidth = widthHeight[0] ? widthHeight[0] + "px" : "";
    el.style.minHeight = widthHeight[1] ? widthHeight[1] + "px" : "";
    this.onResizeEnd();
  }

  setInitPosition(leftTop: [number, number]) {
    if (this.isRestoredState()) return;

    this.setPosition(leftTop);
  }

  setPosition(leftTop: [number, number]) {
    if (this.state.maximized) {
      console.warn("Trying to set position of maximized window");
      return;
    }

    const el = this.rootRef.current!;

    el.style.left = leftTop[0] + "px";
    el.style.top = leftTop[1] + "px";
  }

  minimize() {
    if (this.isLockedDueToPresentationMode()) {
      return;
    }
    
    desktopManager.minimizeWindow(this.props.windowInstance);
  }

  maximizeOnInit() {
    if (this.isRestoredState()) return;

    this.maximize();
  }

  maximize() {
    if (this.isLockedDueToPresentationMode()) {
      return;
    }

    if (this.state.preMaximizeSize) {
      this.setWindowLocation(this.state.preMaximizeSize);
      this.setState({
        preMaximizeSize: undefined,
        maximized: false,
      });
    } else {
      this.setState({
        preMaximizeSize: this.getWindowLocation(),
        maximized: true,
      });
      this.setWindowLocation({
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
      });
    }

    this.onResizeEnd();
  }

  close(result?: any) {
    if (this.isLockedDueToPresentationMode()) {
      return;
    }

    desktopManager.closeWindow(this.props.windowInstance, result);
  }

  getWindowViewSerializedState(): WindowViewSerializedState {
    return {
      lockMode: this.state.lockMode,
      maximized: this.state.maximized,
      preMaximizeSize: this.state.preMaximizeSize,
      windowLocation: this.getWindowLocation(),
    };
  }

  getWindowLocation(): WindowLocation {
    const el = this.rootRef.current!;

    return {
      left: el.style.left!,
      top: el.style.top!,
      width: el.style.width!,
      height: el.style.height!,
    };
  }

  setWindowLocation(location: WindowLocation) {
    const el = this.rootRef.current!;
    el.style.top = location.top;
    el.style.left = location.left;
    el.style.width = location.width;
    el.style.height = location.height;
  }

  activateWindow() {
    if (this.props.windowInstance.isActive) return;

    desktopManager.activate(this.props.windowInstance);
  }

  onRootClick(ev: React.MouseEvent) {
    this.state.onClick.next({});
  }

  onRootMouseOver(ev: React.MouseEvent) {
    if (!this.rootRef.current) return;
    dragHelper.updateCursorStyleForDragResize(this.rootRef.current, ev.nativeEvent);
  }

  onRootMouseDown(ev: React.MouseEvent) {
    this.activateWindow();

    if (!this.rootRef.current) return;

    dragHelper.onMouseDownPotentialResize(this.rootRef.current, ev.nativeEvent, this.onResizeStart, this.onResizeEnd);
  }

  onTitleMouseDown(event: React.MouseEvent) {
    if (this.state.maximized) return;

    this.setState({
      dragging: true,
    });
    const el = this.rootRef.current!;
    dragHelper.startDragMove(el, event.nativeEvent, this.onDragEnd, this.onDragMove);
  }

  getWindowStyle(): React.CSSProperties {
    const { lockMode } = this.state;
    let zIndex = this.props.windowInstance.zIndex;
    if (lockMode === WindowLockMode.noLock) {
      zIndex = zIndex + desktopState.state.windows.length;
    } else if (lockMode === WindowLockMode.lockForeground) {
      zIndex = zIndex + 2 * desktopState.state.windows.length;
    }

    return {
      zIndex,
      display: this.props.windowInstance.minimized ? "none" : "",
    };
  }

  setLockMode(lockMode: WindowLockMode) {
    this.setState({ lockMode });
  }

  isRestoredState() {
    return Boolean(this.props.windowInstance.restoreState);
  }

  setContentComponentSerializedState(state: any) {
    this.contentComponentSerializedState = state;
  }

  private isLockedDueToPresentationMode() {
    return systemState.state.isPresentationModeActive && Boolean(this.props.windowInstance.restoreState);
  }
}
