import { filesService } from "apinet";
import { useTr } from "core/intl";
import React, { useEffect, useMemo } from "react";
import { FileMetadata } from "../../apinet/models";
import { FeedbackMessage, Text } from "../../core/content";
import { useDataLoader } from "../../core/utils/dataHooks";
import { fileOpener } from "../../core/utils/fileOpener";
import { LoadingSpinner } from "../../core/utils/LoadingSpinner";

interface FileContentPreviewProps {
  metadata: FileMetadata;
}

interface RenderProps {
  metadata: FileMetadata;
  data: ArrayBuffer;
}

export const supportedMediaTypes: Record<string, React.ComponentType<RenderProps>> = {
  "image/png": RenderImage,
  "image/jpg": RenderImage,
  "image/jpeg": RenderImage,
  "text/plain": RenderText,
};

export function FileContentPreview(props: FileContentPreviewProps) {
  const { metadata } = props;
  const isSupported = Boolean(supportedMediaTypes[metadata.mediaType]);
  const isRemoved = metadata.removed;
  const [data, op] = useDataLoader(
    () =>
      isSupported ? filesService.byFileId(metadata.id).then(res => res.arrayBuffer()) : Promise.resolve(undefined),
    [metadata]
  );
  const tr = useTr();

  useEffect(() => {
    if (!isSupported && !isRemoved) {
      fileOpener.openByIdInNewWindow(metadata.id);
    }
  }, [isSupported, isRemoved, metadata]);

  if (isRemoved) {
    return <FeedbackMessage blockCenter>Plik usunięty</FeedbackMessage>;
  }

  if (!isSupported) {
    return (
      <div>
        {tr("Nieobsługiwane wyświetlanie pliku {{mediaType}} - otwarto w nowym oknie przeglądarki", {
          mediaType: metadata.mediaType,
        })}
      </div>
    );
  }

  if (op.state.pending) {
    return (
      <div>
        <Text>Wczytywanie zawartości pliku...</Text> <LoadingSpinner />
      </div>
    );
  }

  if (op.state.error) {
    return (
      <div>
        <Text>Błąd wczytywania zawartości pliku</Text>, {op.state.errorMessage}
      </div>
    );
  }

  if (!data) {
    return <Text block>Brak danych pliku</Text>;
  }

  const RenderComponent = supportedMediaTypes[metadata.mediaType];
  return <RenderComponent data={data} metadata={metadata} />;
}

function RenderImage(props: RenderProps) {
  const { data, metadata } = props;
  const b64string = useMemo(
    () => (data ? `data:${metadata.mediaType};base64,` + arrayBufferToBase64(data) : undefined),
    [data, metadata]
  );

  return (
    <img
      style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", width: "auto", display: "block" }}
      src={b64string}
      alt=""
    />
  );
}

function RenderText(props: RenderProps) {
  const { data } = props;
  const text = useMemo(() => new TextDecoder().decode(data), [data]);
  return <pre style={{ overflow: "auto", width: "100%", height: "100%", margin: "0" }}>{text}</pre>;
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}